import appConfig from "@/config/app.config";
import driverDataConfig from "@/config/driverData.config";

export default defineAppConfig({
  ...appConfig,
  ...driverDataConfig,

  auth: {
    confirmEmail: true,
    redirectAfterLogin: "/admin",
    loginProviders: {
      email: true,
      social: ["google"], // empty array to disable
    },
  },

  teams: {
    defaultName: "My team",
    defaultDescription:
      "Your own personal team just for you. Rename it and invite your team-mates or keep rolling solo!",
  },
});

<script setup>
import img from "@/assets/images/error.png";
const props = defineProps({
  error: Object,
});

const is404 = computed(
  () => props.is404 || props.error.message.toLowerCase().includes("not found")
);

const handleError = () => clearError({ redirect: "/" });
</script>
<template>
  <section class="relative bg-emerald-600/5">
    <div class="relative container-fluid">
      <div class="grid grid-cols-1">
        <div
          class="flex flex-col justify-center min-h-screen px-4 mt-[-40px] md:px-10"
        >
          <div class="my-auto text-center title-heading">
            <NuxtLink to="/" class="flex items-center justify-center mb-5">
              <AppLogo />
            </NuxtLink>
            <img :src="img" class="mx-auto" alt="" />
            <h1 class="mt-3 mb-6 text-3xl font-bold md:text-4xl">
              {{ is404 ? "Page Not Found" : "Whoops, this is embarassing." }}
            </h1>
            <p class="text-slate-400">
              <span v-if="is404">
                Whoops, this is embarassing. <br />
                Looks like the page you were looking for wasn't found.
              </span>
              <span v-else>
                Something went wrong <br />
                We're working on it and we'll get it fixed as soon as we can.
              </span>
            </p>

            <div class="mt-4">
              <button @click="handleError" class="btn btn-primary">
                Back to Home
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--end grid-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
</template>

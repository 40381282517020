import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/opt/build/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KlVwwuJRCL from "/opt/build/repo/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import plugin_WLsn00x1qh from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/.nuxt/floating-vue.mjs";
import plugin_w2VlvAEcpa from "/opt/build/repo/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_tbFNToZNim from "/opt/build/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import AutoAnimate_U0yR2wPCPc from "/opt/build/repo/plugins/AutoAnimate.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_KlVwwuJRCL,
  plugin_WLsn00x1qh,
  floating_vue_EIcJ7xiw0h,
  plugin_w2VlvAEcpa,
  plugin_tbFNToZNim,
  chunk_reload_client_UciE0i6zes,
  AutoAnimate_U0yR2wPCPc
]
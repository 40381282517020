export default {
  routeTypes: [
    { label: "Dedicated", value: "DEDICATED" },
    { label: "OTR", value: "OTR" },
    { label: "Regional", value: "REGIONAL" },
    { label: "Local", value: "LOCAL" },
  ],
  licenseClass: [
    { label: "Class A", value: "A" },
    { label: "Class B", value: "B" },
    { label: "Class C", value: "C" },
    { label: "Class D", value: "D" },
  ],
  soloOrTeam: [
    {
      label: "Solo",
      value: "SOLO",
    },
    {
      label: "Team",
      value: "TEAM",
    },
  ],
  endorsements: [
    { label: "Hazmat", value: "HAZMAT" },
    { label: "Xendorsement", value: "XENDORSEMENT" },
    { label: "Tanker", value: "TANKER" },
    { label: "Doubles/Triples", value: "DOUBLESTRIPLES" },
    { label: "Other", value: "OTHER" },
  ],
  driverType: [
    {
      label: "Company Driver",
      value: "COMPANY_DRIVER",
    },
    {
      label: "Owner Operator",
      value: "OWNER_OPERATOR",
    },
    {
      label: "Lease Purchase",
      value: "LEASE_PURCHASE",
    },
    {
      label: "Student",
      value: "STUDENT",
    },
    {
      label: "Prospective Student",
      value: "PROSPECTIVE_STUDENT",
    },
  ],
  experience: [
    { label: "0-3 months", value: "0-3 months", rank: 1 },
    { label: "3-6 months", value: "3-6 months", rank: 2 },
    { label: "6-9 months", value: "6-9 months", rank: 3 },
    { label: "9-12 months", value: "9-12 months", rank: 4 },
    { label: "12-18 months", value: "12-18 months", rank: 5 },
    { label: "18-24 months", value: "18-24 months", rank: 6 },
    { label: "2 years", value: "2 years", rank: 7 },
    { label: "3 years", value: "3 years", rank: 8 },
    { label: "4 years", value: "4 years", rank: 9 },
    { label: "5+ years", value: "5+ years", rank: 10 },
  ],
  freightTypes: [
    { label: "Dry Van", value: "DRY_VAN" },
    { label: "Auto Carrier", value: "AUTO_CARRIER" },
    { label: "Hazmat", value: "HAZMAT" },
    { label: "Stepdeck", value: "STEPDECK" },
    { label: "Oversized", value: "OVERSIZED" },
    { label: "Intermodal", value: "INTERMODAL" },
    { label: "Refrigerated (Reefer)", value: "REFRIGERATED_REEFER" },
    { label: "Flatbed", value: "FLATBED" },
    { label: "Tanker", value: "TANKER" },
    { label: "Doubles/Triples", value: "DOUBLES_TRIPLES" },
    { label: "Household Goods (HHG)", value: "HOUSEHOLD_GOODS" },
    { label: "SpecialIzed/Heavy Haul", value: "SPECIALIZED_HEAVY_HAUL" },
    { label: "Auto Hauler", value: "AUTO_HAULER" },
    { label: "Livestock", value: "LIVESTOCK" },
    { label: "Dry Bulk & Pneumatic", value: "DRY_BULK_PNEUMATIC" },
  ],
  states: [
    ["AL", "Alabama"],
    ["AK", "Alaska"],
    ["AZ", "Arizona"],
    ["AR", "Arkansas"],
    ["CA", "California"],
    ["CO", "Colorado"],
    ["CT", "Connecticut"],
    ["DE", "Delaware"],
    ["FL", "Florida"],
    ["GA", "Georgia"],
    ["HI", "Hawaii"],
    ["ID", "Idaho"],
    ["IL", "Illinois"],
    ["IN", "Indiana"],
    ["IA", "Iowa"],
    ["KS", "Kansas"],
    ["KY", "Kentucky"],
    ["LA", "Louisiana"],
    ["ME", "Maine"],
    ["MD", "Maryland"],
    ["MA", "Massachusetts"],
    ["MI", "Michigan"],
    ["MN", "Minnesota"],
    ["MS", "Mississippi"],
    ["MO", "Missouri"],
    ["MT", "Montana"],
    ["NE", "Nebraska"],
    ["NV", "Nevada"],
    ["NH", "New Hampshire"],
    ["NJ", "New Jersey"],
    ["NM", "New Mexico"],
    ["NY", "New York"],
    ["NC", "North Carolina"],
    ["ND", "North Dakota"],
    ["OH", "Ohio"],
    ["OK", "Oklahoma"],
    ["OR", "Oregon"],
    ["PA", "Pennsylvania"],
    ["RI", "Rhode Island"],
    ["SC", "South Carolina"],
    ["SD", "South Dakota"],
    ["TN", "Tennessee"],
    ["TX", "Texas"],
    ["UT", "Utah"],
    ["VT", "Vermont"],
    ["VA", "Virginia"],
    ["WA", "Washington"],
    ["WV", "West Virginia"],
    ["WI", "Wisconsin"],
    ["WY", "Wyoming"],
  ],
};

import { default as _91id_93At8tDrHnYhMeta } from "/opt/build/repo/pages/admin/companies/[id].vue?macro=true";
import { default as createvZ8yPLmRe7Meta } from "/opt/build/repo/pages/admin/companies/create.vue?macro=true";
import { default as index21XAbFVyggMeta } from "/opt/build/repo/pages/admin/companies/index.vue?macro=true";
import { default as _91id_93iGFhfzdW2lMeta } from "/opt/build/repo/pages/admin/feeds/[id].vue?macro=true";
import { default as createJN9qe9rx7DMeta } from "/opt/build/repo/pages/admin/feeds/create.vue?macro=true";
import { default as index84lgWQahC8Meta } from "/opt/build/repo/pages/admin/feeds/index.vue?macro=true";
import { default as _91id_93QHwdDUygaOMeta } from "/opt/build/repo/pages/admin/forms/[id].vue?macro=true";
import { default as createEsuqX0zxonMeta } from "/opt/build/repo/pages/admin/forms/create.vue?macro=true";
import { default as index1TTbFVuEXCMeta } from "/opt/build/repo/pages/admin/forms/index.vue?macro=true";
import { default as index7qcPXZQ3fgMeta } from "/opt/build/repo/pages/admin/index.vue?macro=true";
import { default as _91id_93HkwqdQlcMYMeta } from "/opt/build/repo/pages/admin/jobs/[id].vue?macro=true";
import { default as createzBHbvCmph2Meta } from "/opt/build/repo/pages/admin/jobs/create.vue?macro=true";
import { default as indexcwZRYbD6pBMeta } from "/opt/build/repo/pages/admin/jobs/index.vue?macro=true";
import { default as indexzqqGRXWt2YMeta } from "/opt/build/repo/pages/admin/me/index.vue?macro=true";
import { default as onboard_45clientrgm0SuEtvGMeta } from "/opt/build/repo/pages/admin/onboard-client.vue?macro=true";
import { default as _91id_93ckaBWxnQTIMeta } from "/opt/build/repo/pages/admin/snippets/[id].vue?macro=true";
import { default as creategA3yLTYjjkMeta } from "/opt/build/repo/pages/admin/snippets/create.vue?macro=true";
import { default as indexOH1A4Aks2IMeta } from "/opt/build/repo/pages/admin/snippets/index.vue?macro=true";
import { default as indextuIHHa4unwMeta } from "/opt/build/repo/pages/admin/teams/[teamId]/companies/index.vue?macro=true";
import { default as _91id_93aENVK61XEXMeta } from "/opt/build/repo/pages/admin/teams/[teamId]/jobs/[id].vue?macro=true";
import { default as createFO0XAIada4Meta } from "/opt/build/repo/pages/admin/teams/[teamId]/jobs/create.vue?macro=true";
import { default as indexorVDEqiRcyMeta } from "/opt/build/repo/pages/admin/teams/[teamId]/jobs/index.vue?macro=true";
import { default as createcg81SGyvIXMeta } from "/opt/build/repo/pages/admin/teams/[teamId]/users/create.vue?macro=true";
import { default as indexBbPnzZO1jQMeta } from "/opt/build/repo/pages/admin/teams/[teamId]/users/index.vue?macro=true";
import { default as index6hgBRa3HX1Meta } from "/opt/build/repo/pages/admin/users/index.vue?macro=true";
import { default as adminAOa9i81ClJMeta } from "/opt/build/repo/pages/admin.vue?macro=true";
import { default as confirm_45emailfrJRNgQGAvMeta } from "/opt/build/repo/pages/auth/confirm-email.vue?macro=true";
import { default as forgot_45passwordAlAeCKA0eHMeta } from "/opt/build/repo/pages/auth/forgot-password.vue?macro=true";
import { default as logging_45incd5b56YjvFMeta } from "/opt/build/repo/pages/auth/logging-in.vue?macro=true";
import { default as loginMdibZuifKQMeta } from "/opt/build/repo/pages/auth/login.vue?macro=true";
import { default as logoutJbkXMhREN1Meta } from "/opt/build/repo/pages/auth/logout.vue?macro=true";
import { default as registers52Wgm7RtVMeta } from "/opt/build/repo/pages/auth/register.vue?macro=true";
import { default as reset_45passwordic6Ocq3MRVMeta } from "/opt/build/repo/pages/auth/reset-password.vue?macro=true";
import { default as _91id_931AV2ody235Meta } from "/opt/build/repo/pages/companies/[id].vue?macro=true";
import { default as hire1sJivcevS7Meta } from "/opt/build/repo/pages/hire.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91id_93L6HAhxwNWzMeta } from "/opt/build/repo/pages/jobs/[company-slug]/[id].vue?macro=true";
import { default as searchJJ7824QbKzMeta } from "/opt/build/repo/pages/jobs/search.vue?macro=true";
import { default as _91_46_46_46path_93p80xfQtrmLMeta } from "/opt/build/repo/layers/storyblok/pages/lp/[...path].vue?macro=true";
import { default as _91state_93rm0ykEebKIMeta } from "/opt/build/repo/pages/states/[state].vue?macro=true";
import { default as indexrs6JWrHMoNMeta } from "/opt/build/repo/pages/states/index.vue?macro=true";
import { default as welcomes95XXS4hcHMeta } from "/opt/build/repo/pages/welcome.vue?macro=true";
import { default as _91id_93t6FS8TaIWwMeta } from "~/pages/jobs/[company-slug]/[id].vue?macro=true";
import { default as _91id_93iO2Kf938kNMeta } from "~/pages/companies/[id].vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: adminAOa9i81ClJMeta?.name,
    path: "/admin",
    meta: adminAOa9i81ClJMeta || {},
    component: () => import("/opt/build/repo/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-companies-id",
    path: "companies/:id()",
    meta: _91id_93At8tDrHnYhMeta || {},
    component: () => import("/opt/build/repo/pages/admin/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-companies-create",
    path: "companies/create",
    meta: createvZ8yPLmRe7Meta || {},
    component: () => import("/opt/build/repo/pages/admin/companies/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-companies",
    path: "companies",
    meta: index21XAbFVyggMeta || {},
    component: () => import("/opt/build/repo/pages/admin/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-feeds-id",
    path: "feeds/:id()",
    meta: _91id_93iGFhfzdW2lMeta || {},
    component: () => import("/opt/build/repo/pages/admin/feeds/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-feeds-create",
    path: "feeds/create",
    meta: createJN9qe9rx7DMeta || {},
    component: () => import("/opt/build/repo/pages/admin/feeds/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-feeds",
    path: "feeds",
    meta: index84lgWQahC8Meta || {},
    component: () => import("/opt/build/repo/pages/admin/feeds/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-forms-id",
    path: "forms/:id()",
    component: () => import("/opt/build/repo/pages/admin/forms/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-forms-create",
    path: "forms/create",
    component: () => import("/opt/build/repo/pages/admin/forms/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-forms",
    path: "forms",
    meta: index1TTbFVuEXCMeta || {},
    component: () => import("/opt/build/repo/pages/admin/forms/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "",
    component: () => import("/opt/build/repo/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-jobs-id",
    path: "jobs/:id()",
    meta: _91id_93HkwqdQlcMYMeta || {},
    component: () => import("/opt/build/repo/pages/admin/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-jobs-create",
    path: "jobs/create",
    meta: createzBHbvCmph2Meta || {},
    component: () => import("/opt/build/repo/pages/admin/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-jobs",
    path: "jobs",
    meta: indexcwZRYbD6pBMeta || {},
    component: () => import("/opt/build/repo/pages/admin/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-me",
    path: "me",
    component: () => import("/opt/build/repo/pages/admin/me/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-onboard-client",
    path: "onboard-client",
    meta: onboard_45clientrgm0SuEtvGMeta || {},
    component: () => import("/opt/build/repo/pages/admin/onboard-client.vue").then(m => m.default || m)
  },
  {
    name: "admin-snippets-id",
    path: "snippets/:id()",
    meta: _91id_93ckaBWxnQTIMeta || {},
    component: () => import("/opt/build/repo/pages/admin/snippets/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-snippets-create",
    path: "snippets/create",
    meta: creategA3yLTYjjkMeta || {},
    component: () => import("/opt/build/repo/pages/admin/snippets/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-snippets",
    path: "snippets",
    meta: indexOH1A4Aks2IMeta || {},
    component: () => import("/opt/build/repo/pages/admin/snippets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-teams-teamId-companies",
    path: "teams/:teamId()/companies",
    component: () => import("/opt/build/repo/pages/admin/teams/[teamId]/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-teams-teamId-jobs-id",
    path: "teams/:teamId()/jobs/:id()",
    component: () => import("/opt/build/repo/pages/admin/teams/[teamId]/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-teams-teamId-jobs-create",
    path: "teams/:teamId()/jobs/create",
    component: () => import("/opt/build/repo/pages/admin/teams/[teamId]/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-teams-teamId-jobs",
    path: "teams/:teamId()/jobs",
    component: () => import("/opt/build/repo/pages/admin/teams/[teamId]/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-teams-teamId-users-create",
    path: "teams/:teamId()/users/create",
    component: () => import("/opt/build/repo/pages/admin/teams/[teamId]/users/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-teams-teamId-users",
    path: "teams/:teamId()/users",
    component: () => import("/opt/build/repo/pages/admin/teams/[teamId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    meta: index6hgBRa3HX1Meta || {},
    component: () => import("/opt/build/repo/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-confirm-email",
    path: "/auth/confirm-email",
    component: () => import("/opt/build/repo/pages/auth/confirm-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    component: () => import("/opt/build/repo/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-logging-in",
    path: "/auth/logging-in",
    meta: logging_45incd5b56YjvFMeta || {},
    component: () => import("/opt/build/repo/pages/auth/logging-in.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginMdibZuifKQMeta || {},
    component: () => import("/opt/build/repo/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutJbkXMhREN1Meta || {},
    component: () => import("/opt/build/repo/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registers52Wgm7RtVMeta || {},
    component: () => import("/opt/build/repo/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordic6Ocq3MRVMeta || {},
    component: () => import("/opt/build/repo/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "companies-id",
    path: "/companies/:id()",
    component: () => import("/opt/build/repo/pages/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: "hire",
    path: "/hire",
    component: () => import("/opt/build/repo/pages/hire.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs-companyslug-id",
    path: "/jobs/:companyslug()/:id()",
    component: () => import("/opt/build/repo/pages/jobs/[company-slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: "jobs-search",
    path: "/jobs/search",
    component: () => import("/opt/build/repo/pages/jobs/search.vue").then(m => m.default || m)
  },
  {
    name: "lp-path",
    path: "/lp/:path(.*)*",
    meta: _91_46_46_46path_93p80xfQtrmLMeta || {},
    component: () => import("/opt/build/repo/layers/storyblok/pages/lp/[...path].vue").then(m => m.default || m)
  },
  {
    name: "states-state",
    path: "/states/:state()",
    component: () => import("/opt/build/repo/pages/states/[state].vue").then(m => m.default || m)
  },
  {
    name: "states",
    path: "/states",
    component: () => import("/opt/build/repo/pages/states/index.vue").then(m => m.default || m)
  },
  {
    name: "welcome",
    path: "/welcome",
    component: () => import("/opt/build/repo/pages/welcome.vue").then(m => m.default || m)
  },
  {
    name: "unbranded-job",
    path: "/u/jobs/:companyslug()/:id()",
    component: () => import("~/pages/jobs/[company-slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: "unbranded-company",
    path: "/u/companies/:id()",
    component: () => import("~/pages/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/register",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/login",
    component: component_45stubRU5sOBTecH
  }
]
export const useIsCustomDomain = () => {
  const {
    public: { APP_URL, DEPLOY_PRIME_URL, RELEASE_STAGE },
  } = useRuntimeConfig();

  const url = computed(() => {
    if (RELEASE_STAGE === "development") return APP_URL;
    if (DEPLOY_PRIME_URL && RELEASE_STAGE !== "production")
      return DEPLOY_PRIME_URL;
    return APP_URL;
  });

  return computed(
    () => useRequestURL().hostname !== new URL(url.value).hostname
  );
};
